import { render, staticRenderFns } from "./TheHome.vue?vue&type=template&id=aaae124a&scoped=true&"
import script from "./TheHome.vue?vue&type=script&lang=js&"
export * from "./TheHome.vue?vue&type=script&lang=js&"
import style0 from "./TheHome.vue?vue&type=style&index=0&id=aaae124a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaae124a",
  null
  
)

export default component.exports