<template>
<div style="background-color:white;">
            <div v-if="node.name == 'التصنيفات'" >
            <div style="padding-bottom: 2.5rem;" class="widget mt-2">
                <h3 class="widget-title">
                    <div @click="expanded = !expanded" class="collapsed">
                        <p class="h3 text-right">{{ node.name }}</p>
                    </div>
                </h3>
            </div>

            </div>
        <div v-else style="margin-top : 20px"  >
                
               <span href="#" @click="expanded = !expanded" >
                   {{ node.name }}
                   </span> 
        </div>
        <div  v-if="expanded">
        <TreeBrowser
        v-for="category in node.subCategories" 
        :key="category.name"
        :node="category"
        />
        </div>
</div>

</template>

<script>
export default {
    name: 'TreeBrowser',
    props: {
        node:Object,
    },
    data(){
        return {
            expanded:false
        }
    }
}
</script>

<style>

</style>